import {applyMiddleware, combineReducers, createStore} from 'redux';

import thunk from 'redux-thunk';
import Reducer from "./reducers/Reducer";

export const rootReducer = combineReducers({
    Reducer
});

const storeMW = createStore(rootReducer, applyMiddleware(thunk));

export default storeMW;