import React, {Component} from "react";

import XYZ from 'ol/source/XYZ';
import Stamen from "ol/source/Stamen";

import 'ol/ol.css';
import "./Map.css";
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import View from 'ol/View';
import proj4 from 'proj4';
import * as proj from 'ol/proj';
import projs from "proj4/projs";
import {register} from "ol/proj/proj4";
import {transform} from 'ol/proj.js';

import {get as getProjection} from 'ol/proj';
import {Image as ImageLayer} from 'ol/layer';
import ImageWMS from 'ol/source/ImageWMS';

import {getHeight, getWidth} from 'ol/extent';

import {toLonLat} from 'ol/proj';
import {toStringHDMS} from 'ol/coordinate';
import Overlay from 'ol/Overlay';
import Control from "ol/control/Control";
// import {Strategy, Protocol} from "ol"
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
// import Interaction from 'ol/interaction/Interaction';
import {Select} from "ol/interaction";
import {actionTypes, getApiDataAction, getFileUploadAction, getSensorDataAction, setMapDataAction} from "../actions";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import ReactDOM from 'react-dom'

const axios = require("axios");

class MapOL extends Component {


    map = null;

    constructor(props) {
        super(props);

        const id = this.props.match.params.id;

        let datasetstart = "";
        if (id == 'ipswich' || id == 'wanneroo') {
            datasetstart = id;
        } else {
            datasetstart = this.props.datasetstart;
        }

        this.state = {
            lng: -70.9,
            lat: 42.35,
            zoom: 9,
            propertyname: this.props.mapData[datasetstart].propertyname,
        };
        this.mapContainer = React.createRef();

        this.map = null;


    }

    componentDidMount() {


        console.log(window);
        this.renderCartSum();


    }




    renderCartSum = () => {

        // proj4.defs["EPSG:900913"] = "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +no_defs";
        // proj4.defs["EPSG:28350"] = "+proj=utm +zone=50 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
        proj4.defs('EPSG:28350', '+proj=utm +zone=50 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
        //projs.register(proj4)
        register(proj4);


        this.xyz = new XYZ({
            url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        })
        this.stamen = new Stamen({
            layer: 'terrain',
        });
        this.toner = new Stamen({
            layer: 'toner'
        });



        this.maplayers = [
            new TileLayer({
                id: 'tilelayer1',
                // source: new XYZ({
                //   url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                // }),
                // source: new Stamen({
                //   layer: 'terrain',
                // }),
                source: this.xyz
            }),
        ];

        // let map = this.state.map;
        this.map = new Map({
            layers: this.maplayers,

            target: 'map',
            view: new View({
                rojection: 'EPSG:4326',
                //center: [0.0],
                //center: [-37.79411252953793,145.282184820610],
                //center: transform([115.784580,-31.757621], 'EPSG:4326', 'EPSG:3857'),
               center: transform([145.282184820610, -37.79411252953793], 'EPSG:4326', 'EPSG:3857'),

                zoom: 11,
                transition: 0,

                // opacity: 0.9
            }),
            //overlays: [overlay],
        });


    }

    handleRadio(e) {
        console.log(e)
    }

    render() {

        return (
            <>
                {/*<div className="container">*/}
                <div id="map" ref={this.mapContainer} style={{height: "780px", marginBottom: "30px"}}/>

            </>

        )

    }
}

// export default MapOpenLayers;

const mapStateToProps = (state) => ({
    microservice_name: state.Reducer.microservice_name,
    apiData: state.Reducer.apiData,
    inputData: state.Reducer.inputData,
    apiError: state.Reducer.apiError,
    sensorData: state.Reducer.sensorData,
    mapData: state.Reducer.mapData,
    datasetstart: state.Reducer.datasetstart
});

const mapDispatchToProps = (dispatch) => ({

    getApiData: (observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation) => dispatch(getApiDataAction(observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation)),
    getFileUpload: (file) => dispatch(getFileUploadAction(file)),
    getSensorData: () => dispatch(getSensorDataAction()),
    setMapData: (start) => dispatch(setMapDataAction(start))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MapOL));
