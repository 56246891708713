import React, { Component } from "react";
import 'maplibre-gl/dist/maplibre-gl.css';
import {Map, NavigationControl} from 'maplibre-gl'

class MaplibreglMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lng: -70.9,
      lat: 42.35,
      zoom: 9
    };
    this.mapContainer = React.createRef();
  }

  componentDidMount() {

    console.log(window);

    const map = new Map( {

      container: this.mapContainer.current,
      style: 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json',
      //center: [151.209900, -33.865143], //sydney
      center: [115.75225765226949, -31.746429646188155], //wannerroo
      //center: [-122.41669, 37.7853], //usa
      zoom: 9 // starting zoom

    })
    var vectorSource = "ne-source";
    var vectorId = "ne-layer";
    var vectorServer = "http://localhost:7800/";
    var vectorSourceLayer = "wifdemo.wanneroo";
    // The data table has a lot of columns, we retrieve just three
    var vectorProps = "?properties=Plan_LU,Exist_LU_S,Area_ha"
    var vectorUrl = vectorServer + vectorSourceLayer + "/{z}/{x}/{y}.pbf" + vectorProps;

    map.on("load", function() {

      // Layers read from sources
      map.addSource(vectorSource, {
        "type": "vector",
        "tiles": [vectorUrl],
        "minzoom": 0,
        "maxzoom": 22
      });

      var vectorLayerColor = "blue";

      const max_expr = ['to-number',['get', 'shape_area']];

      var vectorLayerOutline = {
        "id": vectorId + "-outline",
        "source": vectorSource,
        "source-layer": vectorSourceLayer,
        "type": "fill",
        'paint': {
          'fill-color': ['case', ['>', ['to-number',['get', 'shape_area']], 10000], 'green', 'red']
        }

      };
      map.addLayer(vectorLayerOutline);

      // The fill layer has a unique id and
      // we can tie the click action to it below
      // var vectorLayerFill = {
      //   "id": vectorId,
      //   "source": vectorSource,
      //   "source-layer": vectorSourceLayer,
      //   "type": "fill",
      //   "paint":  {
      //     "fill-color": vectorLayerColor,
      //     "fill-opacity": 0.1,
      //     "fill-outline-color": vectorLayerColor
      //   }
      // };
      // map.addLayer(vectorLayerFill);

    });

  }

  render() {
    return (
         <div>
           <div id="map" ref={this.mapContainer} style={{height: "780px", marginBottom: "30px"}} />
          </div>
    )

  }
}

export default MaplibreglMap;
