import React from 'react';
import PostCreate from "./PostCreate";
import PostList from "./PostList";
import PostForm from "./PostForm";
import MapOpenLayers from "./OpenLayers/MapOpenLayers";
import DatasetList from "./OpenLayers/DatasetList";
import Deckgl from "./Deckgl";


export default function HomePage() {
    return (
        <div className="container">
            {/*<h1>Create Post</h1>*/}
            <PostForm />
            {/*<MapOpenLayers />*/}
            {/*{<DatasetList />}*/}
            {/*{<Deckgl/>}*/}
            <hr />
            {/*<h1>Posts</h1>*/}
            {/*<PostList />*/}
        </div>
    );
}