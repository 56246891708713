import React from 'react';

export default function Footer() {

    return (
        // <><center><h3>AHDAP </h3></center></>
        <></>
    );
}

