import React from "react";
import Header from './Header';
import Footer from "./Footer";
import HomePage from './HomePage';
import {Route, Router, Switch, BrowserRouter} from 'react-router-dom';
import history from './history';
import StepsForm from "./StepsForm";
import './App.css';
import MapOpenLayers from "./OpenLayers/MapOpenLayers";
import Deckgl from "./Deckgl";
import MapOL from "./OpenLayers/MapOL";
import Secured from "./Secured";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak"
import PrivateRoute from "./PrivateRoute";

import Nav1 from './Nav1'
import MaplibreglMap from "./MaplibreglMap";



const App = () => {
  return (
      <div>

          {/*<ReactKeycloakProvider authClient={keycloak}>*/}
          {/*    <Nav1 />*/}
              {/*<BrowserRouter history = {history}  basename="/map">*/}
               <BrowserRouter history = {history}  basename="/">
                  <Header/>
                  <Switch>
                      <Route  path="/" exact component={HomePage} />

                      <Route  path="/steps" exact component={StepsForm} />
                      {/*<Route  path="/preview"  exact component={MapOpenLayers} />*/}
                      {/*<Route path="/preview/:id" exact component={MapOpenLayers} />;*/}
                      {/*<Route  path="/preview"  exact component={Deckgl} />*/}
                      {/*<Route path="/preview/:id" exact component={Deckgl} />;*/}
                      <Route  path="/preview"  exact component={MaplibreglMap} />
                      <Route path="/preview/:id" exact component={MaplibreglMap} />
                      <PrivateRoute> <Route path="/secured" exact component={Secured} /></PrivateRoute>
                     ;
                     {/*<Route path="/secured" component={*/}
                     {/*     <PrivateRoute>*/}
                     {/*         <Secured />*/}
                     {/*     </PrivateRoute>*/}
                     {/* } />*/}
                     {/* <PrivateRoute  path="/secured" component={Secured} />*/}
                     {/* <PrivateRoute  path="/secured" component={Secured} />*/}

                      {/*<Route*/}
                      {/*    path="/ipswich"*/}
                      {/*    render={(props) => <MapOpenLayers {...props} datasetstart={'ipswich'} />}*/}
                      {/*/>*/}
                      {/*<Route*/}
                      {/*    path="/wanneroo"*/}
                      {/*    render={(props) => <MapOpenLayers {...props} datasetstart={`wanneroo`} />}*/}
                      {/*/>*/}



                      {/*<Route*/}
                      {/*    path="/wanneroo"*/}
                      {/*    render={() => (*/}
                      {/*        <MapOpenLayers*/}
                      {/*            //pass the props through the route*/}
                      {/*            datasetstart={'wanneroo'}*/}
                      {/*        />*/}
                      {/*    )}*/}
                      {/*    exact={true}*/}
                      {/*/>*/}

                  </Switch>
                  <Footer/>
              </BrowserRouter>
          {/*</ReactKeycloakProvider>*/}

      </div>
  );
};
export default App;
