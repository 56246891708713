import React, {Component} from "react";

import XYZ from 'ol/source/XYZ';
import Stamen from "ol/source/Stamen";

import 'ol/ol.css';
import "./Map.css";
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import View from 'ol/View';
import proj4 from 'proj4';
import * as proj from 'ol/proj';
import projs from "proj4/projs";
import {register} from "ol/proj/proj4";
import {transform} from 'ol/proj.js';

import {get as getProjection} from 'ol/proj';
import {Image as ImageLayer} from 'ol/layer';
import ImageWMS from 'ol/source/ImageWMS';

import {getHeight, getWidth} from 'ol/extent';

import {toLonLat} from 'ol/proj';
import {toStringHDMS} from 'ol/coordinate';
import Overlay from 'ol/Overlay';
import Control from "ol/control/Control";
// import {Strategy, Protocol} from "ol"
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
// import Interaction from 'ol/interaction/Interaction';
import {Select} from "ol/interaction";
import {actionTypes, getApiDataAction, getFileUploadAction, getSensorDataAction, setMapDataAction} from "../actions";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import ReactDOM from 'react-dom'

const axios = require("axios");

class MapOpenLayers extends Component {


    map = null;

    constructor(props) {
        super(props);

        const id = this.props.match.params.id;

        let datasetstart = "";
        if (id == 'ipswich' || id == 'wanneroo') {
            datasetstart = id;
        } else {
            datasetstart = this.props.datasetstart;
        }

        this.state = {
            lng: -70.9,
            lat: 42.35,
            zoom: 9,
            propertyname: this.props.mapData[datasetstart].propertyname,
            datasetstart: datasetstart,
            mapData: this.props.mapData,
            map: null,
            radioOpenLayers : true,
            radioTerrain : false,
            radioToner : false,

        };
        this.mapContainer = React.createRef();
        this.ref1 = this.mapContainer.current;
        console.log(this.state);

        this.setMapData = this.setMapData.bind(this);
        console.log("constructor");
        console.log(this.state.mapData);

        var proj28350 = getProjection('EPSG:28350');
        this.wmsLayerNew = new TileWMS({
            //url: 'http://localhost:8080/geoserver/wms',
            url: 'https://housingdata.ahdap.org/geoserver/wms',
            projection: proj28350,
            id: 'wmslayer1',
            //params: {'LAYERS': 'whatif:uaz_wanneroo', 'id' : 'wmslayer1', 'TILED': true, VERSION: '1.1.0', 'BBOX' : '363306.8125%2C6475574.0%2C394259.09375%2C6519190.5', 'transparent': true},
            //params: {'LAYERS': 'whatif:ipswich', 'id' : 'wmslayer1', 'TILED': true, VERSION: '1.1.0', 'BBOX' : '1.6963174E7%2C-3232023.25%2C1.7025388E7%2C-3184866.25', 'transparent': true},
            params: {
                'LAYERS': this.state.mapData[this.state.datasetstart].layername,
                'id': 'wmslayer1',
                'TILED': true,
                VERSION: '1.1.0',
                'BBOX': this.state.mapData[this.state.datasetstart].BBOX,
                'transparent': true
            },
            // url: 'https://ahocevar.com/geoserver/wms',
            // params: {'LAYERS': 'topp:states', 'TILED': true},
            serverType: 'geoserver',
            // SRS: 'EPSG:28350',
            //BBOX: [363306.8125,6475574.0,394259.09375,6519190.5],
            version: '1.1.0',
            // opacity: '0.9',
            // Countries have transparency, so do not fade tiles:
            transition: 0,
        });

        this.maplayers = null;

        this.map = null;


    }

    componentDidMount() {


        console.log(window);
        //console.log(this.state.mapData[this.state.datasetstart].propertyname);

        console.log("componentDidMount");
        console.log(this.state.mapData);
        console.log(this.state.datasetstart);

        this.renderCartSum();


    }

    componentDidUpdate() {
        //map.refresh();
    }


    setMapData = (start) => {

        console.log("setMapData");
        console.log(start);


      this.map.getLayers().forEach(layer => {
        if (layer && layer.values_.id === 'tilelayer1') {
          //this.map.removeLayer(layer);
          //this.map.addLayer(stamenlayer);
          if (start == 'stamen') {
              layer.setSource(this.stamen);
              this.setState({
                  radioOpenLayers : false,
                  radioTerrain : true,
                  radioToner : false,
              });
          }

          else if (start == 'toner') {
              layer.setSource(this.toner);
              this.setState({
                  radioOpenLayers : false,
                  radioTerrain : false,
                  radioToner : true,
              });

          }
          else if (start == 'XYZ') {
              layer.setSource(this.xyz);
              this.setState({
                  radioOpenLayers : true,
                  radioTerrain : false,
                  radioToner : false,
              });

          }
          else {
            layer.setSource(this.xyz);
              this.setState({
                  radioOpenLayers : true,
                  radioTerrain : false,
                  radioToner : false,
              });
          }

        }
      });

   /*     this.setState({
            propertyname: this.props.mapData[start].propertyname,
            datasetstart: start,
            mapData: this.props.mapData
        }, () => {
            console.log(this.state)
            this.props.setMapData(start);
        });
*/
    }


    renderCartSum = () => {

        // proj4.defs["EPSG:900913"] = "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +no_defs";
        // proj4.defs["EPSG:28350"] = "+proj=utm +zone=50 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
        proj4.defs('EPSG:28350', '+proj=utm +zone=50 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
        //projs.register(proj4)
        register(proj4);

        //proj28350.setExtent([363306.8125,6475574.0,394259.09375,6519190.5]);

        const wmsSource = new ImageWMS({
            //url: 'http://localhost:8080/geoserver/wms',
            //url: 'https://housingdata.ahdap.org/geoserver/wms',
            url: this.state.mapData[this.state.datasetstart].url,
            //params: {'LAYERS': 'whatif:wanneroo', legend_options: 'countMatched:true', countMatched:true},
            //params: {'LAYERS': 'whatif:uaz_wanneroo', legend_options: 'countMatched:true', countMatched:true},
            //params: {'LAYERS': 'whatif:ipswich', legend_options: 'countMatched:true', countMatched:true},
            params: {
                'LAYERS': this.state.mapData[this.state.datasetstart].layername,
                legend_options: 'countMatched:true',
                countMatched: true
            },
            ratio: 1,
            serverType: 'geoserver',
            countMatched: true,
            legend_options: 'countMatched:true',
        });

        const updateLegend = function (resolution) {
            const graphicUrl = wmsSource.getLegendUrl(resolution);
            const img = document.getElementById('legend');
            const legenOptions = "&legend_options=countMatched:true;fontAntiAliasing:true;hideEmptyRules:true;forceLabels:on"
            img.src = `${graphicUrl}${legenOptions}`;
        };


        const container = document.getElementById('popup');
        const content = document.getElementById('popup-content');
        const closer = document.getElementById('popup-closer');


        const overlay = new Overlay({
            element: container,
            autoPan: {
                animation: {
                    duration: 250,
                },
            },
        });

        closer.onclick = function () {
            overlay.setPosition(undefined);
            closer.blur();
            return false;
        };

        ////

        this.xyz = new XYZ({
            url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        })
        this.stamen = new Stamen({
            layer: 'terrain',
        });
        this.toner = new Stamen({
            layer: 'toner'
        });



        this.maplayers = [
            new TileLayer({
                id: 'tilelayer1',
                // source: new XYZ({
                //   url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                // }),
                // source: new Stamen({
                //   layer: 'terrain',
                // }),
                source: this.xyz
            }),
            new TileLayer({
                source: this.wmsLayerNew,
            }),
        ];

        // let map = this.state.map;
        this.map = new Map({
            layers: this.maplayers,

            target: 'map',
            view: new View({
                rojection: 'EPSG:4326',
                //center: [0.0],
                //center: [115.784580,-31.757621],
                //center: transform([115.784580,-31.757621], 'EPSG:4326', 'EPSG:3857'),
                center: transform(this.state.mapData[this.state.datasetstart].center, 'EPSG:4326', 'EPSG:3857'),

                zoom: 11,
                transition: 0,
                // opacity: 0.9
            }),
            overlays: [overlay],
        });

        const resolution = this.map.getView().getResolution();
        updateLegend(resolution);

        //works
        this.map.getLayers().item(1).setOpacity(0.7);

// Update the legend when the resolution changes
        this.map.getView().on('change:resolution', function (event) {
            const resolution = event.target.getResolution();
            updateLegend(resolution);
        });


        // const getExtent = function () {
        //   return this.map.getView().calculateExtent()
        // }
        // const getProjectionCode = function () {
        //   return this.map.getView().getProjection().getCode()
        // }
        // const crs = `&CRS=${getProjectionCode()}`;
        // const bbox = `&BBOX=${getExtent().join(',')}`


        const propertyname = this.state.mapData[this.state.datasetstart].propertyname;

        this.map.on('singleclick', function (evt) {
            const coordinate = evt.coordinate;
            const hdms = toStringHDMS(toLonLat(coordinate));

            this.forEachLayerAtPixel(evt.pixel, function (layer) {
                var source = layer.getSource();
                console.log(layer);
                return true;
            });


            ///////WORKS
            var view = this.getView();
            var viewResolution = view.getResolution();

            var pixel = evt.pixel;
            //pixel = map.getEventPixel(evt.originalEvent); <-- tried with and without this line
            this.forEachLayerAtPixel(evt.pixel, function (layer) {
                var source = layer.getSource();
                console.log(layer);
                if (layer.getSource().serverType_ == 'geoserver') {
                    //var url = source.getFeatureInfoUrl(evt.coordinate, viewResolution, view.getProjection(), {'INFO_FORMAT': 'application/json', 'propertyName': 'exist_lu_s,shape_area'});
                    //var url = source.getFeatureInfoUrl(evt.coordinate, viewResolution, view.getProjection(), {'INFO_FORMAT': 'application/json', 'propertyName': 'score_ipsw'});
                    var url = source.getFeatureInfoUrl(evt.coordinate, viewResolution, view.getProjection(), {
                        'INFO_FORMAT': 'application/json',
                        'propertyName': propertyname
                    });
                    if (url) {
                        //document.getElementById('nodelist').innerHTML = '<iframe seamless src="' + url + '"></iframe>';

                        console.log(url);

                        axios.get(url, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        }).then(response => {
                            console.log(response.data);
                            var feature = response.data.features[0];
                            var props = feature.properties;
                            //var info = "<h2>" + props.exist_lu_s + "</h2>" + "<h2>" + props.shape_area + "</h2>";

                            var info = "<h2>" + props[propertyname] + "</h2>";
                            if (propertyname == "score_ipsw") {

                                if (props[propertyname] == 0) {
                                    info = "<h2>Not Suitable</h2>";
                                } else if (props[propertyname] > 0.0000000001 && props[propertyname] <= 0.2) {
                                    info = "<h2>Low</h2>";
                                } else if (props[propertyname] > 0.2 && props[propertyname] <= 0.4) {
                                    info = "<h2>Medium-Low</h2>";
                                } else if (props[propertyname] > 0.4 && props[propertyname] <= 0.6) {
                                    info = "<h2>Medium</h2>";
                                } else if (props[propertyname] > 0.6 && props[propertyname] <= 0.8) {
                                        info = "<h2>Medium-High</h2>";
                                } else if (props[propertyname] > 0.8) {
                                    info = "<h2>High</h2>";
                                }
                            }

                            //popup.show(evt.coordinate, info);

                            content.innerHTML = '<p>Value is:</p><code>' + info + '</code>';
                            overlay.setPosition(coordinate);
                            return true;

                        }).catch((error) => {
                            console.log(error);
                        })

                    }
                }

                // return true;
            });


        });
    }

    handleRadio(e) {
        console.log(e)
    }

    render() {

        return (
            <>
                {/*<div className="container">*/}
                <div style={{marginLeft: '30px'}}>
                  <div className="row">
                    <div className="col-3 col-md-3 col-lg-3">


                      <a href="https://www.ahdap.org/" target="_blank">
                        <img src={process.env.PUBLIC_URL + '/logo-ahdap.jpg'} width="50%"/>
                      </a>

                      <h6>
                        <a href={"/map"}>Home</a>
                      </h6>


                      <h2>Dataset : {this.state.datasetstart}</h2>
                      {/*<a type="button" className="btn btn-lg btn-warning"*/}
                      {/*   onClick={() => this.setMapData("wanneroo")}>*/}
                      {/*  Test*/}
                      {/*</a>*/}

                      {/*<h5>Tile</h5>*/}


                        <h5>Map Tile</h5>
                            <div  style={{marginBottom: '30px'}}>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="exampleRadios"
                                           id="exampleRadios1" value="option1"
                                           checked={this.state.radioOpenLayers}
                                           onClick={() => this.setMapData("xyz")}/>
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                        Open Layers
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="exampleRadios"
                                           id="exampleRadios2"
                                           checked={this.state.radioTerrain}
                                           value="option2" onClick={() => this.setMapData("stamen")}/>
                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                        Stamen Terrain
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="exampleRadios"
                                           id="exampleRadios3"
                                           checked={this.state.radioToner}
                                           value="option2" onClick={() => this.setMapData("toner")}/>
                                    <label className="form-check-label" htmlFor="exampleRadios3">
                                        Stamen Toner
                                    </label>
                                </div>
                            </div>



                      <h5>Legend</h5>
                      <img id="legend"/>
                    </div>

                    <div className="col-9 col-md-9 col-lg-9 mt-5">
                      <div id="map" ref={this.mapContainer} style={{height: "780px", marginBottom: "30px"}}/>
                      <div id="popup" className="ol-popup">
                        <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                        <div id="popup-content"></div>


                        <div id="information"></div>


                      </div>

                    </div>
                  </div>
                </div>


            </>

        )

    }
}

// export default MapOpenLayers;

const mapStateToProps = (state) => ({
    microservice_name: state.Reducer.microservice_name,
    apiData: state.Reducer.apiData,
    inputData: state.Reducer.inputData,
    apiError: state.Reducer.apiError,
    sensorData: state.Reducer.sensorData,
    mapData: state.Reducer.mapData,
    datasetstart: state.Reducer.datasetstart
});

const mapDispatchToProps = (dispatch) => ({

    getApiData: (observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation) => dispatch(getApiDataAction(observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation)),
    getFileUpload: (file) => dispatch(getFileUploadAction(file)),
    getSensorData: () => dispatch(getSensorDataAction()),
    setMapData: (start) => dispatch(setMapDataAction(start))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MapOpenLayers));
