import React, { Component } from "react";

import XYZ from 'ol/source/XYZ';

import 'ol/ol.css';
import "./Map.css";
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import View from 'ol/View';
import proj4 from 'proj4';
import * as proj from 'ol/proj';
import projs from "proj4/projs";
import {register} from "ol/proj/proj4";
import {transform} from 'ol/proj.js';

import {get as getProjection} from 'ol/proj';
import {Image as ImageLayer} from 'ol/layer';
import ImageWMS from 'ol/source/ImageWMS';

import { getHeight, getWidth } from 'ol/extent';

import {toLonLat} from 'ol/proj';
import {toStringHDMS} from 'ol/coordinate';
import Overlay from 'ol/Overlay';
import Control from "ol/control/Control";
// import {Strategy, Protocol} from "ol"
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
// import Interaction from 'ol/interaction/Interaction';
import {Select} from "ol/interaction";
import {actionTypes, getApiDataAction, getFileUploadAction, getSensorDataAction, setMapDataAction} from "../actions";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import MapOpenLayers from "./MapOpenLayers";

const axios = require("axios");


class DatasetList extends Component {


  map = null;

  constructor(props) {
    super(props);

    this.state = {
      lng: -70.9,
      lat: 42.35,
      zoom: 9,
      propertyname : this.props.mapData[this.props.datasetstart].propertyname,
      datasetstart : this.props.datasetstart,
      mapData : this.props.mapData,
      map: null
    };
    this.mapContainer = React.createRef();
    console.log(this.state);

    this.setMapData = this.setMapData.bind(this);
    console.log("constructor");
    console.log(this.state.mapData);





  }

  componentDidMount() {


    console.log(window);
    //console.log(this.state.mapData[this.state.datasetstart].propertyname);

    console.log("componentDidMount");
    console.log(this.state.mapData);
    console.log(this.state.datasetstart);

    //this.renderCartSum();



  }

  componentDidUpdate() {
    //map.refresh();
  }

  setMapData(start) {

    console.log("setMapData");
    console.log(start);

    this.setState({
      propertyname : this.props.mapData[start].propertyname,
      datasetstart : start,
      mapData : this.props.mapData
    }, () =>{
      console.log(this.state)
      this.props.setMapData(start);
      this.props.history.push('preview');

    });

  }


  renderCartSum = () => {
    // <ul>{this.renderCartList()}</ul>
  }

  render() {

    return (
         <>


           <div className="row">
             <div className="col-3 col-md-3 col-lg-3">

               {/*<img src="logo-ahdap.jpg" width="50%"/>*/}
               <a href="https://www.ahdap.org/" target="_blank">
                 <img src={process.env.PUBLIC_URL + '/logo-ahdap.jpg'} width="100%"/>
               </a>

             </div>
           </div>

           <h2>Select Dataset for Map Peview</h2>
           <div className="row">
             <div className="col-3 col-md-3 col-lg-3">
               <a type="button" className="btn btn-lg btn-warning"
                  onClick={()=> this.setMapData("wanneroo")}>
                 wanneroo
               </a>

             </div>

           </div>
           <br/>
           <div className="row">
             <div className="col-3 col-md-3 col-lg-3">
             <a type="button" className="btn btn-lg btn-warning"
                onClick={()=> this.setMapData("ipswich")}>
               ipswich
             </a>
             </div>
           </div>

           {/*{<div>{<MapOpenLayers/>}</div>}*/}

          </>

    )

  }
}

// export default MapOpenLayers;

const mapStateToProps = (state) => ({
  microservice_name: state.Reducer.microservice_name,
  apiData: state.Reducer.apiData,
  inputData: state.Reducer.inputData,
  apiError: state.Reducer.apiError,
  sensorData: state.Reducer.sensorData,
  mapData: state.Reducer.mapData,
  datasetstart : state.Reducer.datasetstart
});

const mapDispatchToProps = (dispatch) => ({

  getApiData: (observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation) => dispatch(getApiDataAction(observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation)),
  getFileUpload: (file) => dispatch(getFileUploadAction(file)),
  getSensorData: () => dispatch(getSensorDataAction()),
  setMapData: (start) => dispatch(setMapDataAction(start))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatasetList));
