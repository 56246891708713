import React from 'react';


const Secured = () => {

    // return (
    //     <div>
    //         <h1 className="text-black text-4xl">Welcome to the Protected Page.</h1>
    //     </div>
    // );
    return(
        <div>
            <h1>Welcome to the Protected Page.</h1>
        </div>
    )
};

export default Secured;