import axios from 'axios';
import history from "../history";
import _ from "lodash";

export const actionTypes = {
    API_SUCCESS : 'API_SUCCESS',
    API_FAILURE : 'API_FAILURE',
    GET_CATEGORIES : 'GET_CATEGORIES',
    GET_CATEGORY : 'GET_CATEGORY',
    GET_CATEGORY_PRODUCTS : 'GET_CATEGORY_PRODUCTS',
    GET_PRODUCT : 'GET_PRODUCT',
    GET_CART : 'GET_CART',
    ADD_CART : 'ADD_CART',
    UPDATE_CART : 'UPDATE_CART',
    DELETE_CART : 'DELETE_CART',
    SHOW_CART_MODAL : 'SHOW_CART_MODAL',
    SIGN_IN : 'SIGN_IN',
    SIGN_OUT : 'SIGN_OUT',
    SIGN_UP : 'SIGN_UP',
    GET_PRODUCT_TITLE : 'GET_PRODUCT_TITLE',
    CHANGE_CHECKOUT_TYPE : 'CHANGE_CHECKOUT_TYPE',
    CREATE_ORDER : 'CREATE_ORDER',
    PAYMENT_SUCCESS : 'PAYMENT_SUCCESS',
    SEARCH: 'SEARCH',
    SENSOR_SUCCESS : 'SENSOR_SUCCESS',
    MAP_CHANGE : 'MAP_CHANGE'
};


//const ROOT_URL = "https://solargardenslight.com/suntestapi";//"http://localhost/suntestapi";
const ROOT_URL = "https://solarlightingoutdoor.com.au/api";
const API_KEY = '?key=PAPERCLIP1234';

export const SuccessfullPaymentAction = () => async (dispatch, getState) => {

    const { cart, isSignedIn, checkoutData } = getState().Reducer;
    //email to customer , saba , and create record into database.

    let cartItems = Object.keys(cart);

    let items = [];

    let sum = 0;

    //TODO change to 25
    let shippingCost =25;
    _.map(cartItems, item => {
          let val = {};
          val['itemname'] = cart[item].title;
          val['itemcount'] = cart[item].quantity;
          val['itemprice'] = cart[item].price;
          val['itemdiscount'] = 0;
          val['itemsum'] = cart[item].quantity * cart[item].price;
          sum += cart[item].quantity * cart[item].price;
          items.push(val);
    });


    let address = {
        address1: checkoutData.buyerInfo.shippingAddress.address1,
        address2: checkoutData.buyerInfo.shippingAddress.address2,
        state: checkoutData.buyerInfo.shippingAddress.state,
        postcode: checkoutData.buyerInfo.shippingAddress.postcode,
        country: checkoutData.buyerInfo.shippingAddress.country
    };
    //isguest: isSignedIn? 'true': 'false',
    let data = {
        isguest: isSignedIn ,
        email: checkoutData.buyerInfo.email,
        name: checkoutData.buyerInfo.name,
        address: address,
        cart: items,
        cost : sum,
        shipping: shippingCost,
        total: sum + shippingCost,
        discount: 0,
        promocode: '',
        paymentmethod: 'paypal',
        ipaddress: ''

    };

    console.log(JSON.stringify(data));

    const response =  await axios.post(`${ROOT_URL}/createOrder`, data);
    dispatch({type: actionTypes.PAYMENT_SUCCESS});
    history.push('/thankyou');

};

export const createOrderAction = (formValues) => async (dispatch, getState) => {

    console.log("createOrderAction:" + formValues);

    const { cart } = getState().Reducer;
    console.log(cart);
    //email & save  about incomplete order
    //TODO create an action which saves this order as new and waiting for payment
    //formValues.cart = cart;
    //const response =  await axios.post(`${ROOT_URL}/createOrderWaitingPayment`, formValues);

    dispatch({type: actionTypes.CREATE_ORDER, payload: formValues});
    history.push('/paypal');

};

export const createOrderWithNewUser = (formValues) => async (dispatch, getState) => {

    console.log("createOrderWithNewUser:" + formValues);

    console.log(formValues);

    let signinData = {};
    signinData.email = formValues.email;
    signinData.name = formValues.name;
    signinData.password = formValues.password;
    signinData.address = formValues.address;

    //email & save  about incomplete order
    const response =  await axios.post(`${ROOT_URL}/createOrderWithNewUser`, signinData);

    if (response.data.id > 0 ) {
        //new user saved into database and got an id.
        signinData.orders = [];

        dispatch({type: actionTypes.SIGN_IN, payload: signinData});

        signinData.paymentFinalised = false;
        signinData.dateAndTime = Date.now();
        signinData.ipAddress = "";

        const { cart } = getState().Reducer;

        dispatch({type: actionTypes.CREATE_ORDER, payload: signinData});
        history.push('/paypal');
    }

};

export const registerNewUserAction = (formValues) => async (dispatch, getState) => {

    console.log("registerNewUserAction:" + formValues);

    let data = {};
    data.email = formValues.email;
    data.name = formValues.name;
    data.password = formValues.password;
    data.address = formValues.address;


    const response =  await axios.post(`${ROOT_URL}/registerUser`, data);

    if (response.data.id > 0 ) {
        data.orders = [];
        dispatch({type: actionTypes.SIGN_IN, payload: data});
        history.push('/signin');
    }
};

export const changeCheckoutTypeAction = (value) => async dispatch => {

    //console.log(value);
    dispatch({type: actionTypes.CHANGE_CHECKOUT_TYPE, payload: value});
};


export const searchAction = (value) => async dispatch => {

    console.log(value.name);
    dispatch({type: actionTypes.SEARCH, payload: value.name});
    history.push('/search');
};

export const signIn = (email, password) => async dispatch => {

    console.log("signIn:" + email);
    let data = {
        email: email,
        password: password
    };

    const response =  await axios.post(`${ROOT_URL}/login`, data);
    //console.log(response);

    if (response.data.email) {
        //dispatch(actionTypes.SIGN_IN(response.data));
        dispatch({type: actionTypes.SIGN_IN, payload: response.data});
    }
};

export const resetPassword = (email) => async dispatch => {

    console.log("resetPassword:" + email);
    let data = {
        email: email
    };

    const response =  await axios.post(`${ROOT_URL}/resetPassword`, data);

    //console.log(response);

};


export const signOut = () => async dispatch => {

    console.log("signOut:");
    //dispatch(actionTypes.SIGN_OUT());
    dispatch({type: actionTypes.SIGN_OUT});
};


export const changeCartModalAction = (lsw) => async dispatch => {

    //dispatch(actionTypes.SHOW_CART_MODAL(lsw));
   // dispatch({type: actionTypes.SHOW_CART_MODAL(lsw)});
    dispatch({type: actionTypes.SHOW_CART_MODAL, payload: lsw});
};

export const getCartAction = () => async dispatch => {

    //dispatch(actionTypes.GET_CART());
    dispatch({type: actionTypes.GET_CART});
};

export const addCart = (productId, quantity, price, title, imageUrl, productUrl ) => async dispatch => {

    let data = {productId:productId , quantity: quantity, price: price, title:title, imageUrl:imageUrl, productUrl:productUrl};
    console.log("action:" + data);
    //dispatch(actionTypes.ADD_CART(data));
    dispatch({type: actionTypes.ADD_CART, payload: data});
    //dispatch(actionTypes.GET_CART());
    dispatch({type: actionTypes.GET_CART});
};


export const updateCart = (productId, quantity ) => async dispatch => {

    let data = {productId:productId , quantity: quantity};

    //dispatch(actionTypes.UPDATE_CART(data));
    dispatch({type: actionTypes.UPDATE_CART, payload: data});
    //dispatch(actionTypes.GET_CART());
    dispatch({type: actionTypes.GET_CART});
};

export const deleteCart = (productId ) => async dispatch => {

    let data = {productId:productId};

    //dispatch(actionTypes.DELETE_CART(data));
    dispatch({type: actionTypes.DELETE_CART, payload: data});
    //dispatch(actionTypes.GET_CART());
    dispatch({type: actionTypes.GET_CART});
};




export const getProductById = (id) => async (dispatch) => {

    let data = {};
    console.log("getProductById");
    console.log(id);
    if (Number(id) === 1) {
        data = {id: 1, name: "solar-street-lights-slo-01-15w", title: "Solar Street Lights SLO-01-15W", price: 100, imageUrl:""};
    }  if (Number(id) === 2) {
        data = {id: 2, name: "solar-street-lights-slo-01-22w", title: "Solar Street Lights SLO-01-22W", price: 100, imageUrl:""};
    }
    //dispatch(actionTypes.GET_PRODUCT_TITLE(data));
    dispatch({type: actionTypes.GET_PRODUCT_TITLE, payload: data});

};

export const sendMessage = (name, email, message) => async dispatch => {

    console.log("sendMessage:" + email);
    let data = {
        name: name,
        email: email,
        message: message
    };

     const response =  await axios.post(`${ROOT_URL}/contactUs`, data);
     console.log(response);

};

export const subscribeNewsletterAction = (email) => async dispatch => {

    console.log("sendMessage:" + email);
    let data = {
        email: email
    };

    //console.log(data);

    const response =  await axios.post(`${ROOT_URL}/subscribeEmail`, data);
    //console.log(response);
};



/*
export const getApiDataAction = (observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation) => {

    let postdata = {
        data: {
                observationPeriod: observationPeriod,
                projectionPeriod: projectionPeriod,
                baseYear: baseYear,
                basePopulation: basePopulation,
                nextYear: nextYear,
                nextPopulation: nextPopulation,
                currentYear: currentYear,
                currentPopulation: currentPopulation
        }
    };

    console.log(postdata);

   /!* return (dispatch) => {
        return axios.get('https://citysensors.be.unsw.edu.au/ttnv3/snla1kb03/2021-07-28/', {
            headers: {
                'type': 'ttn3',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(response => {
            console.log(response.data);

            dispatch({type: actionTypes.SENSOR_SUCCESS, payload: response.data})
        }).catch((error)=> {
            dispatch({type: actionTypes.API_FAILURE, payload: "error"})
        })
    }*!/
    //return axios.post('http://AHDAP-Trend-ALB-1224384361.ap-southeast-2.elb.amazonaws.com:4000/postdata', postdata)
   return (dispatch) => {
        return axios.post('http://trend-api:4000/postdata', postdata)
            .then(response => {
                console.log(response.data);
                // let datanew = [response.data.output.projectedPopulation1, response.data.output.projectedPopulation2, response.data.output.projectedPopulation3, response.data.output.projectedPopulation4]
                // let newpayload = response.data
                // newpayload["graphdata"] = datanew;


                let output = {
                    postdata : postdata,
                    apiData: response.data
                }
                // dispatch({type: actionTypes.API_SUCCESS, payload: response.data})
                dispatch({type: actionTypes.API_SUCCESS, payload: output})
            }).catch((error)=> {
                dispatch({type: actionTypes.API_FAILURE, payload: "error"})
            })
    }

};
*/



export const getApiDataAction =  (observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation) => {

    let postdata = {
        data: {
            observationPeriod: observationPeriod,
            projectionPeriod: projectionPeriod,
            baseYear: baseYear,
            basePopulation: basePopulation,
            nextYear: nextYear,
            nextPopulation: nextPopulation,
            currentYear: currentYear,
            currentPopulation: currentPopulation
        }
    };

    console.log(postdata);


    let url = "https://www.ahdap.dev/postdata";
    //url = "http://localhost:4000/api";
   url = "/api";

   /* let response0 =  axios.post(url, postdata) .then(response => {
        console.log(response.data);
    }).catch((error)=> {
        console.log("response0: " + error);
    })
*/

    return (dispatch) => {
        return axios.post(url, postdata)
            .then(response => {
                console.log(response.data);
                // let datanew = [response.data.output.projectedPopulation1, response.data.output.projectedPopulation2, response.data.output.projectedPopulation3, response.data.output.projectedPopulation4]
                // let newpayload = response.data
                // newpayload["graphdata"] = datanew;


                let output = {
                    postdata : postdata,
                    apiData: response.data
                }
                // dispatch({type: actionTypes.API_SUCCESS, payload: response.data})
                dispatch({type: actionTypes.API_SUCCESS, payload: output})
            }).catch((error)=> {
                dispatch({type: actionTypes.API_FAILURE, payload: "error"})
            })
    }

};


export const getFileUploadAction = (file) => async dispatch => {

    console.log("file:" + file);
    // const response =  await axios.post(`${ROOT_URL}/contactUs`, data);
    //dispatch({type: actionTypes.API_SUCCESS, payload: output})
    //const fs = require('fs');
    var reader = new FileReader();
    reader.onload = function(e) {
        const text = e.target.result;
        console.log(text);
    }

    reader.readAsText(file);

    // var data = fs.readFileSync(file, "utf8")

};


export const getSensorDataAction = () => async dispatch => {

       //console.log(data);

    // const response =  await axios.get('https://citysensors.be.unsw.edu.au/ttnv3/snla1kb03/2021-07-28/', {
    //     headers: {
    //         'type': 'ttn3',
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     }
    // });

    console.log("getSensorDataAction");

    return (dispatch) => {
        return axios.get('https://citysensors.be.unsw.edu.au/ttnv3/snla1kb03/2021-07-28/', {
            headers: {
                'type': 'ttn3',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(response => {
                console.log(response.data);

               dispatch({type: actionTypes.SENSOR_SUCCESS, payload: response.data})
            }).catch((error)=> {
                dispatch({type: actionTypes.API_FAILURE, payload: "error"})
            })
    }


};

// export const setMapDataAction =  (datasetname) => {
export const setMapDataAction = (datasetname) => async (dispatch) => {

    // let start = {
    //     datasetstart: datasetname,
    // };

    console.log(datasetname);

    dispatch({type: actionTypes.MAP_CHANGE, payload: datasetname});
    //history.push('/map/preview');

    // return (dispatch) => {
    //
    //
    // }

};



