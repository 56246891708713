import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getApiDataAction, getFileUploadAction, getSensorDataAction} from './actions'
// import history from '../../history';
import {withRouter} from 'react-router';
import LineChart from "./LineChart";
import MaplibreglMap from "./MaplibreglMap";
import MapOpenLayers from "./OpenLayers/MapOpenLayers";
import Deckgl from "./Deckgl";
class PostForm extends Component {


    formData = {};

    constructor(props) {

        super(props);
        this.postFormData = this.postFormData.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.getSensorDataInfo = this.getSensorDataInfo.bind(this);

        //this.formData = this.props.inputData;
        this.state = {
            observationPeriod: 5,
            projectionPeriod: 5,
            baseYear: this.props.inputData.baseYear,
            basePopulation: this.props.inputData.basePopulation,
            nextYear: 2011,
            nextPopulation: this.props.inputData.nextPopulation,
            currentYear: 2016,
            currentPopulation: this.props.inputData.currentPopulation,
            file: null
        };

        this.myRef = React.createRef();

    }

    componentDidMount() {

        console.log(this.props.microservice_name);

    }

    onInputChange(e) {
        console.log(e.target.value);
       /* this.setState(
            {
                observationPeriod: this.props.inputData.observationPeriod,
                projectionPeriod: this.props.inputData.projectionPeriod,
                baseYear: e.target.value,
                basePopulation: this.props.inputData.basePopulation,
                nextYear: this.props.inputData.nextYear,
                nextPopulation: this.props.inputData.nextPopulation,
                currentYear: this.props.inputData.currentYear,
                currentPopulation: this.props.inputData.currentPopulation
            })*/


    }


    postFormData(observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation) {

        console.log(currentPopulation);
        this.props.getApiData(observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation);

    }

    getSensorDataInfo() {

       console.log("getSensorDataInfo");
       this.props.getSensorData();

    }

    onFileChange(event) {
        console.log(event.target.files);
        this.setState({ file: event.target.files[0] }, () =>{
            console.log(this.state)
            this.props.getFileUpload(this.state.file);
        });

    }


     checkServer() {

        if (this.props.apiError != null) {
            return ("Server Is Not Running!")
        } else {
            return null;
        }

    }

     render() {
        return (
            <form>
                <div className="form-group">
                    {/*<label>Title</label>*/}
                    <h1>{this.props.microservice_name}</h1>
                    <h2>{/*this.props.sensorData.length*/}</h2>
                 {/*  { <div className="form-row">
                        <input
                            onChange={this.onFileChange.bind(this)}
                            type="file"
                            accept="text/csv" />
                    </div>}*/}

                    <div className="form-row">
                        <div className="col-6 form-group">
                            <h6>Observation Period</h6>
                            {/*<input type="number" defaultValue={this.props.inputData.observationPeriod}*/}
                            {/*       onChange={(e) => this.onInputChange(e)}></input>*/}
                            <input type="number" defaultValue={this.props.inputData.observationPeriod}
                                onChange={(e) => {
                                    this.setState(
                                        {
                                            observationPeriod: e.target.value,
                                            projectionPeriod: this.props.inputData.projectionPeriod,
                                            baseYear: this.props.inputData.baseYear,
                                            basePopulation: this.props.inputData.basePopulation,
                                            nextYear: this.props.inputData.nextYear,
                                            nextPopulation: this.props.inputData.nextPopulation,
                                            currentYear: this.props.inputData.currentYear,
                                            currentPopulation: this.props.inputData.currentPopulation
                                        })
                                   }
                                }
                            />
                        </div>

                        <div className="col-lg-6 form-group">
                            <h6>Projection Period</h6>
                            <input type="number" defaultValue={this.props.inputData.projectionPeriod}
                                   onChange={(e) => {
                                       this.setState(
                                           {
                                               observationPeriod: this.props.inputData.observationPeriod,
                                               projectionPeriod: e.target.value,
                                               baseYear: this.props.inputData.baseYear,
                                               basePopulation: this.props.inputData.basePopulation,
                                               nextYear: this.props.inputData.nextYear,
                                               nextPopulation: this.props.inputData.nextPopulation,
                                               currentYear: this.props.inputData.currentYear,
                                               currentPopulation: this.props.inputData.currentPopulation
                                           })
                                   }
                                   }
                            />
                            {/*<input type="number" defaultValue={this.props.inputData.projectionPeriod}*/}
                            {/*       onChange={(e) => this.onInputChange(e)}></input>*/}

                        </div>

                        <div className="col-6 form-group">
                            <h6>Base Year</h6>
                            {/*<input type="number" defaultValue={this.props.inputData.baseYear}*/}
                            {/*       onChange={(e) => this.onInputChange(e)}></input>*/}

                            <input type="number" defaultValue={this.props.inputData.baseYear}
                                   onChange={(e) => {
                                       this.setState(
                                           {
                                               observationPeriod: this.props.inputData.observationPeriod,
                                               projectionPeriod: this.props.inputData.projectionPeriod,
                                               baseYear: e.target.value,
                                               basePopulation: this.props.inputData.basePopulation,
                                               nextYear: this.props.inputData.nextYear,
                                               nextPopulation: this.props.inputData.nextPopulation,
                                               currentYear: this.props.inputData.currentYear,
                                               currentPopulation: this.props.inputData.currentPopulation
                                           })
                                   }
                                   }
                            />


                        </div>

                        <div className="col-6 form-group">
                            <h6>Base Population</h6>
                            {/*<input type="number" defaultValue={this.props.inputData.basePopulation}*/}
                            {/*       onChange={(e) => this.onInputChange(e)}></input>*/}

                            <input type="number" defaultValue={this.props.inputData.basePopulation}
                                   onChange={(e) => {
                                       this.setState(
                                           {
                                               observationPeriod: this.props.inputData.observationPeriod,
                                               projectionPeriod: this.props.inputData.projectionPeriod,
                                               baseYear: this.props.inputData.baseYear,
                                               basePopulation: e.target.value,
                                               nextYear: this.props.inputData.nextYear,
                                               nextPopulation: this.props.inputData.nextPopulation,
                                               currentYear: this.props.inputData.currentYear,
                                               currentPopulation: this.props.inputData.currentPopulation
                                           })
                                   }
                                   }
                            />

                        </div>

                        <div className="col-6 form-group">
                            <h6>Next Year</h6>
                            {/*<input type="number" defaultValue={this.props.inputData.nextYear}*/}
                            {/*       onChange={(e) => this.onInputChange(e)}></input>*/}


                            <input type="number" defaultValue={this.props.inputData.nextYear}
                                   onChange={(e) => {
                                       this.setState(
                                           {
                                               observationPeriod: this.props.inputData.observationPeriod,
                                               projectionPeriod: this.props.inputData.projectionPeriod,
                                               baseYear: this.props.inputData.baseYear,
                                               basePopulation: this.props.inputData.basePopulation,
                                               nextYear:  e.target.value,
                                               nextPopulation: this.props.inputData.nextPopulation,
                                               currentYear: this.props.inputData.currentYear,
                                               currentPopulation: this.props.inputData.currentPopulation
                                           })
                                   }
                                   }
                            />


                        </div>

                        <div className="col-6 form-group">
                            <h6>Next Population</h6>
                            {/*<input type="number" defaultValue={this.props.inputData.nextPopulation}*/}
                            {/*       onChange={(e) => this.onInputChange(e)}></input>*/}


                            <input type="number" defaultValue={this.props.inputData.nextPopulation}
                                   onChange={(e) => {
                                       this.setState(
                                           {
                                               observationPeriod: this.props.inputData.observationPeriod,
                                               projectionPeriod: this.props.inputData.projectionPeriod,
                                               baseYear: this.props.inputData.baseYear,
                                               basePopulation: this.props.inputData.basePopulation,
                                               nextYear:  this.props.inputData.nextYear,
                                               nextPopulation: e.target.value,
                                               currentYear: this.props.inputData.currentYear,
                                               currentPopulation: this.props.inputData.currentPopulation
                                           })
                                   }
                                   }
                            />


                        </div>

                        <div className="col-6 form-group">
                            <h6>Current Year</h6>
                            {/*<input type="number" defaultValue={this.props.inputData.currentYear}*/}
                            {/*       onChange={(e) => this.onInputChange(e)}></input>*/}


                            <input type="number" defaultValue={this.props.inputData.currentYear}
                                   onChange={(e) => {
                                       this.setState(
                                           {
                                               observationPeriod: this.props.inputData.observationPeriod,
                                               projectionPeriod: this.props.inputData.projectionPeriod,
                                               baseYear: this.props.inputData.baseYear,
                                               basePopulation: this.props.inputData.basePopulation,
                                               nextYear:  this.props.inputData.nextYear,
                                               nextPopulation: this.props.inputData.nextPopulation,
                                               currentYear: e.target.value,
                                               currentPopulation: this.props.inputData.currentPopulation
                                           })
                                   }
                                   }
                            />

                        </div>

                        <div className="col-6 form-group">
                            <h6>Current Population</h6>
                            {/*<input type="number" defaultValue={this.props.inputData.currentPopulation}*/}
                            {/*       onChange={(e) => this.onInputChange(e)}></input>*/}


                            <input type="number" defaultValue={this.props.inputData.currentPopulation}
                                   onChange={(e) => {
                                       this.setState(
                                           {
                                               observationPeriod: this.props.inputData.observationPeriod,
                                               projectionPeriod: this.props.inputData.projectionPeriod,
                                               baseYear: this.props.inputData.baseYear,
                                               basePopulation: this.props.inputData.basePopulation,
                                               nextYear:  this.props.inputData.nextYear,
                                               nextPopulation: this.props.inputData.nextPopulation,
                                               currentYear: this.props.inputData.currentYear,
                                               currentPopulation: e.target.value
                                           })
                                   }
                                   }
                            />

                        </div>



                    </div>


                    <div className="form-group">
                        <a type="button" className="btn btn-lg btn-warning"
                           onClick={()=> this.postFormData(this.state.observationPeriod, this.state.projectionPeriod,
                               this.state.baseYear, this.state.basePopulation, this.state.nextYear, this.state.nextPopulation,
                               this.state.currentYear, this.state.currentPopulation)}>
                            Calculate
                        </a>
                    </div>

                    <div className="form-group">

                        <h1>{this.checkServer()}</h1>
                    </div>

                    <div className="form-group">
                        <LineChart/>
                    </div>
                    <div className="form-group">
                        <h2>Population</h2>
                    </div>
                    <div className="form-group">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>2006</th>
                                <th>2011</th>
                                <th>2016</th>
                                <th>2021</th>
                                <th>2026</th>
                                <th>2031</th>
                                <th>2036</th>
                            </tr>
                            </thead>
                            <tbody>

                            <td>
                                {Math.round(this.state.basePopulation)}
                            </td>
                            <td>{Math.round(this.state.nextPopulation)}</td>
                            <td>{Math.round(this.state.currentPopulation)}</td>
                            <td>{Math.round(this.props.apiData.output.projectedPopulation1)}</td>
                            <td>{Math.round(this.props.apiData.output.projectedPopulation2)}</td>
                            <td>{Math.round(this.props.apiData.output.projectedPopulation3)}</td>
                            <td>{Math.round(this.props.apiData.output.projectedPopulation4)}</td>

                            </tbody>
                        </table>

                    </div>

                    <div className="form-group">
                        {/*<MaplibreglMap/>*/}
                        {/*<MapOpenLayers/>*/}
                        {/*<Deckgl/>*/}
                    </div>



                </div>
            </form>

        )
    }

}

const mapStateToProps = (state) => ({
    microservice_name: state.Reducer.microservice_name,
    apiData: state.Reducer.apiData,
    inputData: state.Reducer.inputData,
    apiError: state.Reducer.apiError,
    sensorData: state.Reducer.sensorData
});

const mapDispatchToProps = (dispatch) => ({

    getApiData: (observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation) => dispatch(getApiDataAction(observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation)),
    getFileUpload: (file) => dispatch(getFileUploadAction(file)),
    getSensorData: () => dispatch(getSensorDataAction())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostForm));
