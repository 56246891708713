

import React, { Component } from "react";
import 'maplibre-gl/dist/maplibre-gl.css';
// import {Map, NavigationControl} from 'maplibre-gl'
import DeckGL from '@deck.gl/react';
import {LineLayer} from '@deck.gl/layers';
import MaplibreglMap from "./MaplibreglMap";
import {MapView, FirstPersonView} from '@deck.gl/core';
import { StaticMap } from 'react-map-gl';
import Map, {Marker} from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import {GeoJsonLayer, PolygonLayer} from '@deck.gl/layers';
import {scaleThreshold} from 'd3-scale';
import {LightingEffect, AmbientLight, _SunLight as SunLight} from '@deck.gl/core';
import {COORDINATE_SYSTEM} from '@deck.gl/core';
import MapOL from "./OpenLayers/MapOL";

class Deckgl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // lng: -70.9,
            // lat: 42.35,
            //     longitude: -122.41669,
            //     latitude: 37.7853,
            // longitude: 152.76042684220246, //ipswich
            // latitude:  -27.602572403261924,
            longitude: 145.2821848206101,
            latitude:  -37.79411252953793,
                zoom: 11,
                pitch: 0,
                bearing: 0
        };
        this.mapContainer = React.createRef();
        const data = [
            {sourcePosition: [-122.41669, 37.7853], targetPosition: [-122.41669, 37.781]}
        ];


        this.map = null;

        const COLOR_SCALE = scaleThreshold()
            .domain([-0.6, -0.45, -0.3, -0.15, 0, 0.15, 0.3, 0.45, 0.6, 0.75, 0.9, 1.05, 1.2])
            .range([
                [65, 182, 196],
                [127, 205, 187],
                [199, 233, 180],
                [237, 248, 177],
                // zero
                [255, 255, 204],
                [255, 237, 160],
                [254, 217, 118],
                [254, 178, 76],
                [253, 141, 60],
                [252, 78, 42],
                [227, 26, 28],
                [189, 0, 38],
                [128, 0, 38]
            ]);

        // const landCover = [
        //     [
        //         [-123.0, 49.196],
        //         [-123.0, 49.324],
        //         [-123.306, 49.324],
        //         [-123.306, 49.196]
        //     ]
        // ];


        const landCover = [
            [
                [-27.602572403261924, 152.76042684220246, ],
                [-27.324, 152.0, ],
                [-27.324, 152, ],
                [-27, 152, ]
            ]
        ];

        const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json';

        const ambientLight = new AmbientLight({
            color: [255, 255, 255],
            intensity: 1.0
        });

        const dirLight = new SunLight({
            timestamp: Date.UTC(2019, 7, 1, 22),
            color: [255, 255, 255],
            intensity: 1.0,
            _shadow: true
        });

        const layersnew = [
            // only needed when using shadows - a plane for shadows to drop on

        ];

        const geojson = {
            type: 'FeatureCollection',
            features: [
                {type: 'Feature', geometry: {type: 'Point', coordinates: [ -27.602572403261924, 152.76042684220246]}}
            ]
        };
        this.layers = [
            new LineLayer({id: 'line-layer', data}),
            new PolygonLayer({
                id: 'ground',
                data: landCover,
                stroked: false,
                getPolygon: f => f,
                getFillColor: [0, 0, 0, 0]
            }),
          /*  new GeoJsonLayer({
                id: 'geojson',
                data: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/geojson/vancouver-blocks.json',
                opacity: 0.8,
                stroked: false,
                filled: true,
                extruded: true,
                wireframe: true,
                getElevation: f => Math.sqrt(f.properties.valuePerSqm) * 10,
                getFillColor: f => COLOR_SCALE(f.properties.growth),
                getLineColor: [255, 255, 255],
                pickable: true
            }),*/
            new GeoJsonLayer({
                id: 'geojson',
                //data: 'https://housingdata.ahdap.org/geoserver/whatif/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=whatif%3Aipswich&maxFeatures=50&outputFormat=application%2Fjson&SRSname=EPSG:4326',
                //data: 'https://data.gov.au/data/dataset/0436fcc3-7a1a-42ab-a483-23f72b61facc/resource/56ed4366-0c65-4df3-a681-67534de50482/download/city-of-port-phillip-council-buildings.geojson',
                //data: 'https://housingdata.ahdap.org/geoserver/topp/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=topp%3Atasmania_water_bodies&maxFeatures=50&outputFormat=application%2Fjson',
                //data : 'https://housingdata.ahdap.org/geoserver/whatif/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=whatif%3Auaz_wanneroo&maxFeatures=50&outputFormat=application%2Fjson',
                data : 'http://localhost:8080/geoserver/whatif/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=whatif%3Amce&outputFormat=application%2Fjson',
                //data: geojson,
                opacity: 0.8,
                stroked: false,
                filled: true,
                extruded: true,
                wireframe: true,
                getElevation: f => Math.sqrt(f.properties.m1area) * 200,
                // getElevation: f => f.properties.m1area,
                // getFillColor: f => COLOR_SCALE(f.properties.m1area),
                //getFillColor: [255, 255, 255, 255],
                getFillColor: [160, 160, 180, 200],
                getLineColor: [183, 49, 44, 255],
                // getLineColor: [255, 255, 255],
                pickable: true,
                // coordinateSystem: COORDINATE_SYSTEM.LNGLAT_OFFSETS
            }),

            //https://housingdata.ahdap.org/geoserver/whatif/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=whatif%3Aipswich&maxFeatures=50&outputFormat=application%2Fjson
        ];
        // this.layers.push(layersnew);

        this.effects = (() => {
            const lightingEffect = new LightingEffect({ambientLight, dirLight});
            lightingEffect.shadowColor = [0, 0, 0, 0.5];
            return [lightingEffect];
        });



    }


    componentDidMount() {

        console.log(window);

    }

    getTooltip({object}) {
        return (
            object && {
                html: `\
  <div><b>Average Property Value</b></div>
      
       <div>${object.properties.m1area * 100}</div>
  <div>${object.properties.m1area} / parcel</div>
  <div>${object.properties.m1strata} / m<sup>2</sup></div>
  <div><b>Growth</b></div>
  <div>${Math.round(object.properties.m1frontage * 100)}%</div>
  `
            }
        );
    }

    render() {
        return (
            <div>
                {/*<div id="map" ref={this.mapContainer} style={{height: "780px", marginBottom: "30px"}} />*/}
                {/*<MaplibreglMap/>*/}

                  <DeckGL
                    initialViewState={this.state}
                    controller={true}
                    //effects={this.effects}
                    layers={this.layers}
                    getTooltip={this.getTooltip}
                  >

                      {/*<MapOL/>*/}
                      <Map
                          /*initialViewState={{
                              latitude: -27.602572403261924, //37.8,
                              longitude: 152.76042684220246, //-122.4,
                              zoom: 14
                          }}*/
                          mapLib={maplibregl}
                          style={{width: 800, height: 600}}
                          mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                      >
                          <Marker longitude={-122.4} latitude={37.8} color="red" />
                      </Map>




                    </DeckGL>;



            </div>
        )

    }
}

export default Deckgl;
