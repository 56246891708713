import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getApiDataAction, getFileUploadAction, getSensorDataAction} from './actions'
// import history from '../../history';
import {withRouter} from 'react-router';
import LineChart from "./LineChart";
import MaplibreglMap from "./MaplibreglMap";
import { Steps, Button, message } from 'antd';
import './helper.css';

const { Step } = Steps;

const steps = [
    {
        title: 'First',
        content: 'First-content',
    },
    {
        title: 'Second',
        content: 'Second-content',
    },
    {
        title: 'Last',
        content: 'Last-content',
    },
];




class StepsForm extends Component {


    formData = {};

    constructor(props) {

        super(props);
        this.state = {
            current: 1
        };

    }

    next() {
        this.setState(
            {
                current: this.state.current + 1
            })
    }

    prev() {
        this.setState(
            {
                current: this.state.current - 1
            })
    }

    componentDidMount() {

        console.log(this.props.microservice_name);

    }

    onInputChange(e) {
        console.log(e.target.value);

    }


     render() {
        return (

         <>
             <h1>Steps</h1>
             <Steps current={this.state.current}>
                 {steps.map(item => (
                     <Step key={item.title} title={item.title} />
                 ))}
             </Steps>
             <div className="steps-content">{steps[this.state.current].content}</div>
             <div className="steps-action">
                 {this.state.current < steps.length - 1 && (
                     <Button type="primary" onClick={() => this.next()}>
                         Next
                     </Button>
                 )}
                 {this.state.current === steps.length - 1 && (
                     <Button type="primary" onClick={() => message.success('Processing complete!')}>
                         Done
                     </Button>
                 )}
                 {this.state.current > 0 && (
                     <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                         Previous
                     </Button>
                 )}
             </div>
         </>

        )
    }

}

const mapStateToProps = (state) => ({
    microservice_name: state.Reducer.microservice_name,
    apiData: state.Reducer.apiData,
    inputData: state.Reducer.inputData,
    apiError: state.Reducer.apiError,
    sensorData: state.Reducer.sensorData
});

const mapDispatchToProps = (dispatch) => ({

    getApiData: (observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation) => dispatch(getApiDataAction(observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation)),
    getFileUpload: (file) => dispatch(getFileUploadAction(file)),
    getSensorData: () => dispatch(getSensorDataAction())
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepsForm));
