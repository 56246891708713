import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getApiDataAction} from './actions'
// import history from '../../history';
import {withRouter} from 'react-router';
import {
    Chart,
    BarElement,
    BarController,
    CategoryScale,
    LinearScale,
    Legend,
    Title,
    Tooltip, LineElement, LineController, PointElement
} from 'chart.js';

Chart.register(
    BarElement,
    BarController,
    LineElement,
    PointElement,
    LineController,
    CategoryScale,
    LinearScale,
    Legend,
    Title,
    Tooltip)

const labels = [
    '2006',
    '2011',
    '2016',
    '2021',
    '2026',
    '2031',
    '2036'
];


class LineChart extends React.Component {

    newdata = {
        labels: labels,
        datasets: [{
            label: 'Linear Trend',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: null
        }]
    };


    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidUpdate() {
        // this.myChart.data.labels = this.props.data.map(d => d.time);
        let graphdata1 = [this.props.inputData.basePopulation, this.props.inputData.nextPopulation, this.props.inputData.currentPopulation]
        let graphdata2 = [this.props.apiData.output.projectedPopulation1, this.props.apiData.output.projectedPopulation2, this.props.apiData.output.projectedPopulation3, this.props.apiData.output.projectedPopulation4]
        let arr2  = [...graphdata1, ...graphdata2]

        this.myChart.data.datasets[0].data = arr2;
         this.myChart.update();
    }





    componentDidMount() {
        this.myChart = new Chart(this.chartRef.current, {
            type: 'line',
            data: this.newdata
        });
    }

    render() {
        return <canvas ref={this.chartRef} />;
    }
}


const mapStateToProps = (state) => ({
    microservice_name: state.Reducer.microservice_name,
    apiData: state.Reducer.apiData,
    inputData: state.Reducer.inputData
});


const mapDispatchToProps = (dispatch) => ({

    getApiData: (observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation) => dispatch(getApiDataAction(observationPeriod, projectionPeriod, baseYear, basePopulation, nextYear, nextPopulation, currentYear, currentPopulation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LineChart));