import {actionTypes} from "../actions/index";
import _ from 'lodash';


export const initialState = {
    "inputData": {
        observationPeriod: 5,
        projectionPeriod: 5,
        baseYear: 2006,
        basePopulation: 140181,
        nextYear: 2011,
        nextPopulation: 166904,
        currentYear: 2016,
        currentPopulation: 193733
    },
    apiData: {
        "output": {
            "projectedPopulation1": null,
            "projectedPopulation2": null,
            "projectedPopulation3": null,
            "projectedPopulation4": null
        }
    },
    // graphdata: [140181, 166904, 193733, 0, 0, 0, 0],
    apiError: null,
    userData: null,
    microservice_name: "Linear Trends",
    sensorData: [],
    datasetstart : "ipswich",
    mapData: {
        "ipswich": {
            "url": 'https://housingdata.ahdap.org/geoserver/wms',
            "layername": 'whatif:ipswich',
            "BBOX": '1.6963174E7%2C-3232023.25%2C1.7025388E7%2C-3184866.25',
            "propertyname": "score_ipsw",
            "extent": [1.6963174E7,-3232023.25,1.7025388E7,-3184866.25],
            "center" : [152.761221,-27.617078]
        },
        "wanneroo": {
            "url": 'https://housingdata.ahdap.org/geoserver/wms',
            "layername": 'whatif:uaz_wanneroo',
            "BBOX": '363306.8125%2C6475574.0%2C394259.09375%2C6519190.5',
            "propertyname": 'exist_lu_s',
            "extent": [363306.8125, 6475574.0, 394259.09375, 6519190.5],
            "center" : [115.784580,-31.757621]
        }
    },
};


export default  (state= initialState, action) => {

    let newItem = {};
    let newcart = {};
    let newUserData = {};

    switch (action.type) {

        case actionTypes.API_SUCCESS:


            // newItem = {
            //     inputData: action.payload.postdata.data,
            //     apiData: action.payload.apiData,
            //     apiError: null
            // };
            // return {...state, apiData: action.payload};

            console.log(newItem);


            return {...state, inputData: action.payload.postdata.data, apiData:action.payload.apiData, apiError: null};
        case actionTypes.SENSOR_SUCCESS:

            return {...state, sensorData: action.payload};
        case actionTypes.MAP_CHANGE:

            console.log("action.payload map");
            console.log(action.payload)
            return {...state, datasetstart: action.payload};

        case actionTypes.API_FAILURE:
            return {...state, apiError: "error"};
        case actionTypes.ADD_CART:
            newItem = {
                quantity: action.payload.quantity,
                price: action.payload.price,
                title: action.payload.title,
                imageUrl: action.payload.imageUrl,
                productUrl: action.payload.productUrl
            };

            newcart = {...state.cart, [action.payload.productId]:newItem};
            return {...state, cart:newcart};

        case actionTypes.SIGN_IN:

            newItem = {
                email: action.payload.email,
                name: action.payload.name,
            };

            newUserData = {...state.userData, newItem};

            return {...state, isSignedIn: true, userData: newItem, checkoutType: {
                    type: "existingUser",
                    sameAddress: "one"
                }};

        

        case actionTypes.SIGN_OUT:

            newItem = {
                email:'',
                name: ''
            };

            return {...state, isSignedIn: false, userData:newItem,
                checkoutType: {
                    type: "guestUser",
                    sameAddress: "one"
                }
            };
            
        default:
            return state;

    }
}

// Data generation
function getRandomArray(numItems) {
    // Create random array of objects
    let names = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let data = [];
    for(var i = 0; i < numItems; i++) {
        data.push({
            label: names[i],
            value: Math.round(20 + 80 * Math.random())
        });
    }
    return data;
}

function getRandomDateArray(numItems) {
    // Create random array of objects (with date)
    let data = [];
    let baseTime = new Date('2018-05-01T00:00:00').getTime();
    let dayMs = 24 * 60 * 60 * 1000;
    for(var i = 0; i < numItems; i++) {
        data.push({
            time: new Date(baseTime + i * dayMs),
            value: Math.round(20 + 80 * Math.random())
        });
    }
    return data;
}

function getData() {
    let data = [];

    data.push({
        title: 'Visits',
        data: getRandomDateArray(150)
    });

    data.push({
        title: 'Categories',
        data: getRandomArray(20)
    });

    data.push({
        title: 'Categories',
        data: getRandomArray(10)
    });

    data.push({
        title: 'Data 4',
        data: getRandomArray(6)
    });

    return data;
}
